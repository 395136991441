
.step {
    text-align: center;
    position: relative;
    // border: solid 3px $casper-blue;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;

    .step-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        /* additional styles for the circle */
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: $casper-blue;
        h1 {
            margin: 0px;
            color: $casper-white;
          }  
        
    }
      

    h3 {
        margin-top: 20px;
        margin-bottom: 12px;
    }
    img {
        max-width: 230px;

        border: 50%;
    }

}


@media only screen and (min-width: 800px) {
    .steps-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex: 50%;
    } .step {
        width: 400px;
        margin: 20px;
    }



}