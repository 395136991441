.onboarding-main {
    .onboard-form {
        .form-btn-container {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 15px;
        }
    
    }
    
    .form-section {
        width: 100%;
        margin-bottom: 20px;
        .addressinputcontainer {
            width: 100%;
        }
        .address-input{
            padding: 10px;
            width: 100%;
            border-radius: 10px;
            border-style: solid;
        }
    }
    
    .pac-item {
        padding: 15px;
    }
    
    
    
      .scroll-menu {
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
      }
    
    
    //   .casper-box-shadow {
    //     box-shadow: 18px 16px 0px 2px $casper-secondary;
    //     -webkit-box-shadow: 18px 16px 0px 2px $casper-secondary;
    //     -moz-box-shadow: 18px 16px 0px 2px $casper-secondary;
    //     color: $casper-secondary;
    //     border-style: solid;
    //     border-width: 3px;
    //     border-radius: 3px;
    //     margin-bottom: 30px;
    
    //   }
    
      .selected-storage-unit-container {
        color: $casper-secondary;
        border-style: solid;
        border-width: 3px;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-around;
        background-color: white;
    
    
        .selected-storage-unit {
        //   display: flex;
          .storage-video {
            display: flex;
            justify-content: center;
            img {
                width: 100%;
                padding: 20px;
    
            }
          }
          .storage-description {
            width: auto;
            margin: 20px;
            color: $casper-black;
    
            h3 {
              font-family: $second-font !important;
              text-align: center;
    
            }
            h4 {
              font-family: $second-font !important;
              font-size: 0.8rem;
              text-align: center;
    
    
            }
            h1 {
              font-family: $main-font !important; 
              bottom: 0px;
              text-align: right;
              width: 100%;
            }
            p {
              font-size: 0.8rem;
            }
    
            .price-tag {
              h1 {
    
              }
    
            }
    
          }
        }
      }
    
      .unit-select {
        overflow: auto;
        white-space: nowrap;
        .selected-unit {
          background-color: $casper-blue;
          h2 {
            color: $casper-white !important;
    
          }
        }
        .unit {
          display: inline-block;
          color: white;
          text-align: center;
          text-decoration: none;
          width: 100px;
          padding: 14px;
          margin-right: -2px;
          
    
          border-style: solid;
          border-width: 2px;
          border-color: $casper-blue;
          h2 {
            margin-bottom: 0px; 
            font-family: 'Ubuntu';
            font-size: 0.9rem;
    
          }
    
    
        }
    
      }
    
    .helpful-tip {
        margin: 18px;
    }
    
    
    .casper-option-select-container {
        .casper-option-card {
            border: solid 2px $casper-grey;
            text-align: center;
            margin: 15px;
            padding: 10px;
            border-radius: 10px;
            h3, h2 {
                padding: 0;
                margin: 0;
                font-size: 0.9rem !important;
            }
            .price {
                color: $casper-blue;
            }
        }
    
        .casper-option-card-selected {
            border: solid 4px $casper-blue;
        }
    }
    
    
    
    
    .onboard-form {
        .form-content {
            margin-bottom: 15px;
    
            .input-element {
                margin: 10px;
    
                label {
                    display: block;
                }
                input {
                    display: block;
                    width: 100%;
                    padding: 10px;
                    border-radius: 10px;
                    border-style: solid;
                }
            }
        }
    
    }
    
    /*Date Picker */
    .ui-datepicker {
        margin-left: auto;
        margin-right: auto;
        width: 100% !important;
        .ui-datepicker-header {
            background-color: $casper-secondary !important;
        }
        .ui-datepicker-calendar {
    
            .ui-state-default {
                padding: 10px;
                border-radius: 5px;
            }
        }
    }
    
    .onboard-action {
        display: flex;
        justify-content: center;
        .button-section {
            .onboard-action-button {
                margin: 25px;
            }
        
        }
    
    }
    
.invalid-input .address-input {
  border: solid rgb(188, 86, 86) 2px;
}

.invalid-input .address-input:focus {
  border: solid rgb(188, 86, 86) 2px;
}



}



@media only screen and (min-width: 800px) {
  .onboarding-main {
    margin-left: 15%;
    margin-right: 15%;
    .selected-storage-unit-container {
      width: 60%;
      .selected-storage-unit {
        display: flex;
        position: relative;
        .price-tag {
          position: absolute;
          bottom: 0px;
        }
      }
    }
  }


}