.whatweoffer-card {
    margin: 10px;
    padding: 30px;
    border-radius: 10px;
    background: #FDFDFD;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    text-align: center;
    h2  {
        color: #4A97A4;
        font-weight: 700;

    }
    p {
        margin-bottom: 20px;
    }

}


@media only screen and (min-width: 800px) {
    .whatweoffer-cards {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: space-around;
    }
    

}
