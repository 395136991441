.cta-hero-banner {
    text-align: center;
    margin-top: 30px;

    .cta-hero-text {
        margin-bottom: 30px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        h1 {
          color: $casper-dark-text;
          font-size: 32px;
          .price {
            color: $casper-blue !important;
          }
        }

    }
}




@media only screen and (min-width: 800px) {
    .cta-hero-banner {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        margin: 30px;
        justify-content: center;
        .cta-hero-text {
            margin: 20px;
            margin-right: 100px;
            width: 20%;

            h1 {
                text-align: left;
                font-size: 42px;
            }
        }
    }
}