.hero-banner {
    //background-color: $casper-secondary;
    text-align: center;
    padding: 30px; 
    display: flex;
    flex-direction: column-reverse;

    .hero-image{
        img {
            width: 100%;
            background-size: 100%;
            padding-left: 5%;
            padding-right: 0%;
            padding-top: 0%;
        }

    }

    .hero-content {
        
        .hero-header {
            
            text-align: center;
            h1 {
                color: $casper-dark-text !important;
                font-weight: 600;

            }

        }
        .hero-paragraph {
            
            p {
                color: #367985;

           }
    }
        // button {
        //     width: 50%; 
        //     background-color: $casper-blue;
        //     border: none;
        //     color: $casper-white;
        //     padding: 10px;
        //     font-size: 1.4rem;
        //     border-radius: 10px;
        // }
    }
}


@media only screen and (min-width: 800px) {
    .hero-banner {
        padding-top: 130px;
        display: flex;
        padding-left: 15%;
        padding-right: 15%;
        justify-content: space-evenly;
        flex-direction: row;
        .hero-image {
            // width: 50%;
            img {
                height: 100%;
                    width: auto;
            }
        }
        .hero-content {
            margin-top: 70px;
            .hero-header {
                width: 400px;
                h1 {
                    text-align: left;

                }
            }
        }
    }    
}
