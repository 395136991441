

$casper-white: #FDFDFD;
$casper-black: #484848; 
$casper-grey: #cccccc; 
$casper-dark-text: #2ea3b8;

$casper-blue-dark: #4A97A4;
$casper-blue: #5EB1BF;
$casper-secondary: #CBEAF0;


$fonts: 'Lato';


$main-font: 'Ubuntu', sans-serif;
$second-font: 'Lato', sans-serif;


h1, h2, h3, h4, h5, p {
    color: $casper-black;
}

h1, h2, h3 {
    font-family: $main-font;
}

h4, h5, p {
    font-family: $second-font;
}


.drop-shadow {
    box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.18);
    -webkit-box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.18);
    -moz-box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.18);
}

.drop-shadow-secondary {
    box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.05);
    -webkit-box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.05);

}

/* Buttons */

h1 {
    text-align: center;
}

.section {
    margin-top: 50px;
    margin-bottom: 0px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    h1 {
        text-align: center;
        color: #367985;
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 2.1rem;
    }
}



.casper-btn {
    padding: 10px;
    border: none;
    width: 120px;
    border-radius: 10px;
    box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.18);
    -webkit-box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.18);
    -moz-box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.18);
}

.casper-btn:hover {
    // background-color: $casper-blue-dark;
    box-shadow: none;
}

.casper-btn-blue {
    background-color: $casper-blue;
    color: $casper-white;
}

.casper-btn-white {
    background-color: $casper-white;
    color: $casper-blue;
}

.CTA-btn {
    display: flex;
    justify-content: center;
    button {
        width: 250px !important;
    }
}

.desktop-content {

}

.component-disabled {
    opacity: 0.2;
    pointer-events: none;
}



/* Better */ 

/* Typography */

h1 {
    color: #5EB1BF;
    font-size: 42px;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

/* Buttons */
.button {
    padding: 10px 25px 10px 25px;
    border: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    font-weight: 500;
}
.button:hover {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

}




.button-primary {
    border-radius: 6px;
    background: #5EB1BF;
    color: $casper-white;

}

.button-secondary {
    border-radius: 6px;
    color: #4A97A4;
    background: #CBEAF0;


}

.button-outline {
    border-radius: 6px;
    border: 3px solid #5EB1BF;
}



@media only screen and (min-width: 800px) {
    .navbar-btn {
        margin-left: 30px;
    }
    
}