$background-stuff-img: '../../public/background-stuff.svg';


.section {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    .casper-block {
        background-image: url($background-stuff-img);
        background-size: cover;

        width: 80%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        padding: 30px;
        background-color: $casper-secondary;
        border-radius: 10px;

        h3 {
            font-weight: 500;
            color: $casper-white;
            font-family: $main-font;

        }
         p {
            color: $casper-white;
        }
    }
}

