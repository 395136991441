.section {
    h1 {
        margin-bottom: 20px;
    }
    .img-card-grid {
        .img-item-container {
            text-align: center;
            border-radius: 10px;

            .item {
                position: relative;

                margin-top: 10px;
                margin-bottom: 20px;
                .img-item {
                    width:100%;
                    border-radius: 10px;
                    object-fit: cover;
                    height: 200px;
                }
                .item-content {
                    position: absolute;
                    bottom: 0px;
                    padding: 10px;
                    background-image: linear-gradient(rgba(218, 218, 218, 0), rgb(86, 86, 86));
                    width: 100%;
                    border-radius: 10px;
                    h3 {
                        color: $casper-white;
                    }
                }
            }

        }
    }
}

@media only screen and (min-width: 800px) {
    .img-card-grid {
        display: flex;
        justify-content: space-evenly;
        margin-left: 10%;
        margin-right: 10%;
        .item {
            width: 300px;
        }
    }
    
}