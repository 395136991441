



    .page-title-container { 
        height: 100px;
        width: 100%;
        margin-top: 16px;
        margin-bottom: 15px;
        background-color: $casper-secondary !important;
        display: flex;
        align-items: center;

        .page-title {
            // overflow: hidden;
            border-radius: 10px;
            width: 100%;

            // img {
            //     width:100%;
            //     object-fit: cover;
            //     height: 125px;
            //     filter:brightness(85%);
            // }

        }



    h1 {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        color: $casper-blue-dark;
        width: 100%;
        font-weight: 600 !important;

    }
}