.comparison-table {
    width: 100%;
    table {
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        th {
            text-align: center;
            img {

            }

            h3 {
                color: $casper-black;
                opacity: 0.5;
                font-size: 0.8rem;
                text-align: center;
            }
        }

        tr:nth-child(even) {
            background-color: #FBFBFB;
        }
        td {
            padding: 10px;
            text-align: center;
            font-size: smaller;
            width: 30%;
            img {
                width: 30px;
            }
        }
        .row-head {
            text-align: left;
        }
    }

}

.table-container {
    border-radius: 10px;
    padding: 10px 8px 10px 8px;
}

.logo-comparison {
    width: 80px;
}

@media only screen and (min-width: 800px) {
    .logo-comparison {
        width: 100px;
        
    }
    .table-container{ 
        padding: 10px;
        margin-left: auto;
        margin-right: auto;
        width: 700px;    
    }
    td {
        padding: 10px;
        text-align: center;
        width: auto;
        img {
            width: 30px;
        }
    }
}