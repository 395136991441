.loadingcontainer {
    width: 100%;
    height: 100vh;
    position: fixed;

    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
        img {
            width: 150px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        .loading-animation {
            width: 100px;
            margin-bottom: 30px;
        }
    }

}