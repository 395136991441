
.bg-light {
    background-color: #FDFDFD !important;
    transition: all 0.5s ease;
}

.logo-dark {
    color: green !important;

}

.bg-dark {
    background-color: #5EB1BF !important;
    transition: all 1s ease;

}



.casper-dark a {
    color: $casper-white !important;
}

.dropdown-menu a {
    color: $casper-black !important;
}


.navbar-brand {
    margin-top: 10px;
    margin-left: 10px;
}

