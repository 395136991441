h1, h2, h3, h4, h5, p {
  color: #484848;
}

h1, h2, h3 {
  font-family: "Ubuntu", sans-serif;
}

h4, h5, p {
  font-family: "Lato", sans-serif;
}

.drop-shadow {
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.18);
}

.drop-shadow-secondary {
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
}

/* Buttons */
h1 {
  text-align: center;
}

.section {
  margin-top: 50px;
  margin-bottom: 0px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.section h1 {
  text-align: center;
  color: #367985;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 2.1rem;
}

.casper-btn {
  padding: 10px;
  border: none;
  width: 120px;
  border-radius: 10px;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.18);
}

.casper-btn:hover {
  box-shadow: none;
}

.casper-btn-blue {
  background-color: #5EB1BF;
  color: #FDFDFD;
}

.casper-btn-white {
  background-color: #FDFDFD;
  color: #5EB1BF;
}

.CTA-btn {
  display: flex;
  justify-content: center;
}
.CTA-btn button {
  width: 250px !important;
}

.component-disabled {
  opacity: 0.2;
  pointer-events: none;
}

/* Better */
/* Typography */
h1 {
  color: #5EB1BF;
  font-size: 42px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Buttons */
.button {
  padding: 10px 25px 10px 25px;
  border: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  font-weight: 500;
}

.button:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.button-primary {
  border-radius: 6px;
  background: #5EB1BF;
  color: #FDFDFD;
}

.button-secondary {
  border-radius: 6px;
  color: #4A97A4;
  background: #CBEAF0;
}

.button-outline {
  border-radius: 6px;
  border: 3px solid #5EB1BF;
}

@media only screen and (min-width: 800px) {
  .navbar-btn {
    margin-left: 30px;
  }
}
.bg-light {
  background-color: #FDFDFD !important;
  transition: all 0.5s ease;
}

.logo-dark {
  color: green !important;
}

.bg-dark {
  background-color: #5EB1BF !important;
  transition: all 1s ease;
}

.casper-dark a {
  color: #FDFDFD !important;
}

.dropdown-menu a {
  color: #484848 !important;
}

.navbar-brand {
  margin-top: 10px;
  margin-left: 10px;
}

.hero-banner {
  text-align: center;
  padding: 30px;
  display: flex;
  flex-direction: column-reverse;
}
.hero-banner .hero-image img {
  width: 100%;
  background-size: 100%;
  padding-left: 5%;
  padding-right: 0%;
  padding-top: 0%;
}
.hero-banner .hero-content .hero-header {
  text-align: center;
}
.hero-banner .hero-content .hero-header h1 {
  color: #2ea3b8 !important;
  font-weight: 600;
}
.hero-banner .hero-content .hero-paragraph p {
  color: #367985;
}

@media only screen and (min-width: 800px) {
  .hero-banner {
    padding-top: 130px;
    display: flex;
    padding-left: 15%;
    padding-right: 15%;
    justify-content: space-evenly;
    flex-direction: row;
  }
  .hero-banner .hero-image img {
    height: 100%;
    width: auto;
  }
  .hero-banner .hero-content {
    margin-top: 70px;
  }
  .hero-banner .hero-content .hero-header {
    width: 400px;
  }
  .hero-banner .hero-content .hero-header h1 {
    text-align: left;
  }
}
.section h1 {
  margin-bottom: 20px;
}
.section .img-card-grid .img-item-container {
  text-align: center;
  border-radius: 10px;
}
.section .img-card-grid .img-item-container .item {
  position: relative;
  margin-top: 10px;
  margin-bottom: 20px;
}
.section .img-card-grid .img-item-container .item .img-item {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  height: 200px;
}
.section .img-card-grid .img-item-container .item .item-content {
  position: absolute;
  bottom: 0px;
  padding: 10px;
  background-image: linear-gradient(rgba(218, 218, 218, 0), rgb(86, 86, 86));
  width: 100%;
  border-radius: 10px;
}
.section .img-card-grid .img-item-container .item .item-content h3 {
  color: #FDFDFD;
}

@media only screen and (min-width: 800px) {
  .img-card-grid {
    display: flex;
    justify-content: space-evenly;
    margin-left: 10%;
    margin-right: 10%;
  }
  .img-card-grid .item {
    width: 300px;
  }
}
.section {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
.section .casper-block {
  background-image: url("../../public/background-stuff.svg");
  background-size: cover;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 30px;
  background-color: #CBEAF0;
  border-radius: 10px;
}
.section .casper-block h3 {
  font-weight: 500;
  color: #FDFDFD;
  font-family: "Ubuntu", sans-serif;
}
.section .casper-block p {
  color: #FDFDFD;
}

.page-title-container {
  height: 100px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 15px;
  background-color: #CBEAF0 !important;
  display: flex;
  align-items: center;
}
.page-title-container .page-title {
  border-radius: 10px;
  width: 100%;
}
.page-title-container h1 {
  color: #4A97A4;
  width: 100%;
  font-weight: 600 !important;
}

.step {
  text-align: center;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.step .step-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  /* additional styles for the circle */
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #5EB1BF;
}
.step .step-circle h1 {
  margin: 0px;
  color: #FDFDFD;
}
.step h3 {
  margin-top: 20px;
  margin-bottom: 12px;
}
.step img {
  max-width: 230px;
  border: 50%;
}

@media only screen and (min-width: 800px) {
  .steps-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex: 50%;
  }
  .step {
    width: 400px;
    margin: 20px;
  }
}
footer img {
  width: 100px;
}

.comparison-table {
  width: 100%;
}
.comparison-table table {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.comparison-table table th {
  text-align: center;
}
.comparison-table table th h3 {
  color: #484848;
  opacity: 0.5;
  font-size: 0.8rem;
  text-align: center;
}
.comparison-table table tr:nth-child(even) {
  background-color: #FBFBFB;
}
.comparison-table table td {
  padding: 10px;
  text-align: center;
  font-size: smaller;
  width: 30%;
}
.comparison-table table td img {
  width: 30px;
}
.comparison-table table .row-head {
  text-align: left;
}

.table-container {
  border-radius: 10px;
  padding: 10px 8px 10px 8px;
}

.logo-comparison {
  width: 80px;
}

@media only screen and (min-width: 800px) {
  .logo-comparison {
    width: 100px;
  }
  .table-container {
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 700px;
  }
  td {
    padding: 10px;
    text-align: center;
    width: auto;
  }
  td img {
    width: 30px;
  }
}
.loadingcontainer {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingcontainer div img {
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.loadingcontainer div .loading-animation {
  width: 100px;
  margin-bottom: 30px;
}

.onboarding-main {
  /*Date Picker */
}
.onboarding-main .onboard-form .form-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.onboarding-main .form-section {
  width: 100%;
  margin-bottom: 20px;
}
.onboarding-main .form-section .addressinputcontainer {
  width: 100%;
}
.onboarding-main .form-section .address-input {
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  border-style: solid;
}
.onboarding-main .pac-item {
  padding: 15px;
}
.onboarding-main .scroll-menu {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.onboarding-main .selected-storage-unit-container {
  color: #CBEAF0;
  border-style: solid;
  border-width: 3px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-around;
  background-color: white;
}
.onboarding-main .selected-storage-unit-container .selected-storage-unit .storage-video {
  display: flex;
  justify-content: center;
}
.onboarding-main .selected-storage-unit-container .selected-storage-unit .storage-video img {
  width: 100%;
  padding: 20px;
}
.onboarding-main .selected-storage-unit-container .selected-storage-unit .storage-description {
  width: auto;
  margin: 20px;
  color: #484848;
}
.onboarding-main .selected-storage-unit-container .selected-storage-unit .storage-description h3 {
  font-family: "Lato", sans-serif !important;
  text-align: center;
}
.onboarding-main .selected-storage-unit-container .selected-storage-unit .storage-description h4 {
  font-family: "Lato", sans-serif !important;
  font-size: 0.8rem;
  text-align: center;
}
.onboarding-main .selected-storage-unit-container .selected-storage-unit .storage-description h1 {
  font-family: "Ubuntu", sans-serif !important;
  bottom: 0px;
  text-align: right;
  width: 100%;
}
.onboarding-main .selected-storage-unit-container .selected-storage-unit .storage-description p {
  font-size: 0.8rem;
}
.onboarding-main .unit-select {
  overflow: auto;
  white-space: nowrap;
}
.onboarding-main .unit-select .selected-unit {
  background-color: #5EB1BF;
}
.onboarding-main .unit-select .selected-unit h2 {
  color: #FDFDFD !important;
}
.onboarding-main .unit-select .unit {
  display: inline-block;
  color: white;
  text-align: center;
  text-decoration: none;
  width: 100px;
  padding: 14px;
  margin-right: -2px;
  border-style: solid;
  border-width: 2px;
  border-color: #5EB1BF;
}
.onboarding-main .unit-select .unit h2 {
  margin-bottom: 0px;
  font-family: "Ubuntu";
  font-size: 0.9rem;
}
.onboarding-main .helpful-tip {
  margin: 18px;
}
.onboarding-main .casper-option-select-container .casper-option-card {
  border: solid 2px #cccccc;
  text-align: center;
  margin: 15px;
  padding: 10px;
  border-radius: 10px;
}
.onboarding-main .casper-option-select-container .casper-option-card h3, .onboarding-main .casper-option-select-container .casper-option-card h2 {
  padding: 0;
  margin: 0;
  font-size: 0.9rem !important;
}
.onboarding-main .casper-option-select-container .casper-option-card .price {
  color: #5EB1BF;
}
.onboarding-main .casper-option-select-container .casper-option-card-selected {
  border: solid 4px #5EB1BF;
}
.onboarding-main .onboard-form .form-content {
  margin-bottom: 15px;
}
.onboarding-main .onboard-form .form-content .input-element {
  margin: 10px;
}
.onboarding-main .onboard-form .form-content .input-element label {
  display: block;
}
.onboarding-main .onboard-form .form-content .input-element input {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border-style: solid;
}
.onboarding-main .ui-datepicker {
  margin-left: auto;
  margin-right: auto;
  width: 100% !important;
}
.onboarding-main .ui-datepicker .ui-datepicker-header {
  background-color: #CBEAF0 !important;
}
.onboarding-main .ui-datepicker .ui-datepicker-calendar .ui-state-default {
  padding: 10px;
  border-radius: 5px;
}
.onboarding-main .onboard-action {
  display: flex;
  justify-content: center;
}
.onboarding-main .onboard-action .button-section .onboard-action-button {
  margin: 25px;
}
.onboarding-main .invalid-input .address-input {
  border: solid rgb(188, 86, 86) 2px;
}
.onboarding-main .invalid-input .address-input:focus {
  border: solid rgb(188, 86, 86) 2px;
}

@media only screen and (min-width: 800px) {
  .onboarding-main {
    margin-left: 15%;
    margin-right: 15%;
  }
  .onboarding-main .selected-storage-unit-container {
    width: 60%;
  }
  .onboarding-main .selected-storage-unit-container .selected-storage-unit {
    display: flex;
    position: relative;
  }
  .onboarding-main .selected-storage-unit-container .selected-storage-unit .price-tag {
    position: absolute;
    bottom: 0px;
  }
}
.whatweoffer-card {
  margin: 10px;
  padding: 30px;
  border-radius: 10px;
  background: #FDFDFD;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.whatweoffer-card h2 {
  color: #4A97A4;
  font-weight: 700;
}
.whatweoffer-card p {
  margin-bottom: 20px;
}

@media only screen and (min-width: 800px) {
  .whatweoffer-cards {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-around;
  }
}
.cta-hero-banner {
  text-align: center;
  margin-top: 30px;
}
.cta-hero-banner .cta-hero-text {
  margin-bottom: 30px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.cta-hero-banner .cta-hero-text h1 {
  color: #2ea3b8;
  font-size: 32px;
}
.cta-hero-banner .cta-hero-text h1 .price {
  color: #5EB1BF !important;
}

@media only screen and (min-width: 800px) {
  .cta-hero-banner {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin: 30px;
    justify-content: center;
  }
  .cta-hero-banner .cta-hero-text {
    margin: 20px;
    margin-right: 100px;
    width: 20%;
  }
  .cta-hero-banner .cta-hero-text h1 {
    text-align: left;
    font-size: 42px;
  }
}
.page-content {
  padding-top: 120px;
}

