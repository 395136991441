@import "./_utilities.scss";
@import "./components/Navbar.scss";
@import "./components/Hero.scss";
@import "./components/OurServices.scss";
@import "./components/WhyChooseUs.scss";
@import "./components/PageTitle.scss";
@import "./components/Howitworks.scss";
@import "./components/Footer.scss";
@import "./components/ServiceComparison.scss";
@import "./components/LoaderContainer.scss";
@import "./components/onboarding/FormSection.scss";
@import "./components/WhatWeOffer.scss";
@import "./components/CTA_Hero.scss";

.page-content {
    padding-top: 120px;
}
